import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllBibleQuestionsCount, getAllQuestionsCount, getBibleQuestion, getCompleteQuestion, getQuestionsBiblePagination, getQuestionsPagination, getSearchAllQuestion, getSearchBibleQuestionByTerm, getSearchQuestionByTerm } from '../../store/actions/questionActions';
import QuestionBlock from '../views/Q&A/QuestionBlock';
import CrossPreload from '../loaders/CrossPreload';
import {Pagination} from '@mui/material'
import SearchQuestionBlock from '../views/Q&A/SearchQuestionBlock';
import LoadMoreBtn from '../component/LoadMoreBtn';
import NothingNotFound from '../component/NothingNotFound';

const AllQuestions = ({isSearchPage, isBiblePage}) => {

    const {
      questions,
      bibleQuestions,
      lastDocument,
      isGettingQuestions,
      isGettingQuestionsForSearch,
      allQuestions,
      allLastDocument,
      allQuestionsSearch,
  } = useSelector((state) => state.quest);

  const dispatch = useDispatch()

  const itemsPerPage = 25

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState(questions);
  const [lastDocuments, setLastDocuments] = useState(null);
  const [topicDropDown, setTopicDropDown] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [allQuestionsCount, setAllQuestionsCount] = useState(0)
  const [isSearch, setIsSearch] = useState(false)

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  const handleSearch = () => {
    setCurrentPage(1)
    if (searchTerm) {
      setIsSearch(true)
      const filtered = questions.filter(question => {
        let contentMatch = question.questionContent.toLowerCase().includes(searchTerm.toLowerCase())
        let topicNameMatch = question.topic.toLowerCase().includes(searchTerm.toLowerCase())
        return contentMatch || topicNameMatch
      })
      setFilteredQuestions(filtered)
    } else {
      setIsSearch(false)
      if (!isBiblePage) {
        dispatch(getQuestionsPagination(currentPage, itemsPerPage))
      } else {
        dispatch(getQuestionsBiblePagination(currentPage, itemsPerPage))
      }
    }
  };
  
  const handleLoadMore = () => {
    if (!isBiblePage)
      dispatch(getCompleteQuestion(lastDocuments, 20));
    else
     dispatch(getBibleQuestion(lastDocuments, 20));
  }

  const handlePageChange = (event, value) => setCurrentPage(value)
  
  const setGetAllQuestionsCount = async () => {
    const count = await getAllQuestionsCount()
    setAllQuestionsCount(count)
  }

  const setGetAllBibleQuestionsCount = async () => {
    const count = await getAllBibleQuestionsCount()
    setAllQuestionsCount(count)
  }
  
  useEffect(() => {
    setLastDocuments(allLastDocument);
  }, [allLastDocument]);
  
  useEffect(() => {
    if (isSearchPage && !isBiblePage) {
      dispatch(getQuestionsPagination(currentPage, itemsPerPage));
      setGetAllQuestionsCount()
    } else if (!isBiblePage && !isSearchPage) {
      dispatch(getCompleteQuestion(null, 20));
    } else if (isSearchPage && isBiblePage) {
      dispatch(getQuestionsBiblePagination(currentPage, itemsPerPage));
      setGetAllBibleQuestionsCount()
    } else if (!isSearchPage && isBiblePage) {
      dispatch(getBibleQuestion(null, 20))
    }
  }, []);
    
    useEffect(() => {
      setFilteredQuestions(!isBiblePage ? allQuestions : bibleQuestions);
    }, [allQuestions, bibleQuestions]);
    
    useEffect(() => {

      setFilteredQuestions(questions);

      const getUniqueTopics = (questionsArray) => {

        const uniqueTopicsMap = new Map()
        questionsArray.forEach((question) => {
          const { id, topic } = question;
          if (!uniqueTopicsMap.has(topic)) {
            uniqueTopicsMap.set(topic, id)
          }
        })

        return uniqueTopicsMap
      };
      const uniqueTopicsMap = getUniqueTopics(questions);
      const topicDropDownOptions = Array.from(uniqueTopicsMap).map(([topic, id]) => ({id, topic}))
      
      setTopicDropDown(topicDropDownOptions);

    }, [questions]);
    

  useEffect(() => {
    if (!isBiblePage) {
      dispatch(getQuestionsPagination(currentPage, itemsPerPage))
    }
  }, [currentPage])

  return (
    <div className='w-full flex flex-col items-center justify-center' style={{minHeight: '100vh'}}>

        {isGettingQuestions && filteredQuestions ? <CrossPreload/> : (
          <div className='w-11/12 sm:w-1/2 flex flex-col items-center'>
            {isSearchPage &&  
              <SearchQuestionBlock 
                handleSearch={handleSearch}
                handleSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                isLoading={isGettingQuestionsForSearch}
            />}
           
            {filteredQuestions.map((question) => 
              <QuestionBlock key={question.id} questionData={question}/>
            )}

            {isSearchPage && filteredQuestions.length === 0 && (
              <NothingNotFound/>
            )}

            {!isSearchPage && filteredQuestions.length === 0 && !isGettingQuestions && <NothingNotFound/>}

            {isSearchPage && !isSearch ? (
              <Pagination
                  className='m-4'
                  count={Math.ceil(allQuestionsCount / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  size='large'
                  color='secondary'
                  variant='outlined'
                />
            ) : !isSearch &&(
              <LoadMoreBtn
                isLoading={isGettingQuestions}
                handleLoadMore={handleLoadMore}
              />
            )}


          </div>
        )}

    </div>
  )
}

export default AllQuestions

import React, { useEffect, useState } from 'react'
import NavLink from './component/NavLink'
import { links } from './constants/data'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchAllQuestionForSearch } from '../store/actions/questionActions'

const Navbar = () => {
  const dispatch = useDispatch()
  const {allQuestionsSearch, isGettingQuestionsForSearch} = useSelector((state) => state.quest)

  useEffect(() => {
    if (allQuestionsSearch.length === 0 && !isGettingQuestionsForSearch)
      dispatch(getSearchAllQuestionForSearch())
  }, [])

  return (      
  <nav className={`w-full p-1 sm:p-2 flex items-center justify-center sm:justify-end fixed top-0 right-0 z-50 navbar-background `}>
    <div className='flex sm:p-3 items-center justify-between space-x-2 sm:space-x-5'> 
      {links.map((link, index) => <NavLink key={index} {...link}/>)}
    </div>
  </nav>
  )
}

export default Navbar
import React from 'react'

const WelcomeSection = () => {
  return (
    <section className='w-full h-full my-20 sm:my-0 sm:h-screen flex flex-col sm:flex-row items-center justify-center text-black'>

      <div className='w-4/5 sm:w-2/5 flex flex-col items-start space-y-6 text-position'>
        <div className='font-comforta-700 text-sm sm:text-4xl'>
          <h1>Здравствуйте! Вы находитесь на сайте священника Павла Островского!</h1>
        </div>
        <div className='font-comforta-700 text-xs sm:text-xl'>
          <p>
            Здесь Вы можете ознакомиться с деятельностью отца Павла.
            На навигационной панели находятся ответы на вопросы заданные слушателями прямых эфиров отца Павла.

            Здесь так же собраны разборы Библии с прихожанами и интервью с Павлом Островским.
            <br/>Приятного пользования!
          </p>
        </div>

      </div>

        <div className='w-full sm:w-1/2 flex items-end justify-center sm:justify-end m-3'>
          <img 
            className='w-2/4 sm:w-2/3 rounded-xl shadow-2xl shadow-black'
            src="pavel_ostrovski.jpg" 
            alt="pavel_ostrovski.jpg" 
          />
        </div>

    </section>
  )
}

export default WelcomeSection

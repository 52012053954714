import React from 'react'
import Questions from '../../pages/Questions'
import AllQuestions from '../../pages/AllQuestions'

const SearchBible = () => {
  return (
    <AllQuestions isSearchPage={true} isBiblePage={true}/>
  )
}

export default SearchBible

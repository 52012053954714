import React from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const PreloaderQuestionBlock = () => {
  return (
    <div className='w-full bg-orange-200 m-2 rounded-xl flex flex-col items-start space-y-4 justify-between font-oswald-300 p-2  animate-pulse'>
      <div className='flex items-center w-full justify-between whitespace-nowrap'>
        <h1 className={`text-xs sm:text-3xl py-3 px-16 bg-orange-300 rounded-xl`}></h1>

        <div className='w-full flex justify-end mx-3'>
          <span className='text-xl' ></span>
        </div>

        <div className='text-xs sm:text-2xl whitespace-nowrap sm:space-x-3 space-x-3 flex items-center'>
          <span className='py-3 px-10 bg-orange-300 rounded-xl'></span>
          <span className='py-3 px-6 bg-orange-300 rounded-xl'></span>
        </div>  
      </div>
      <div className='text-sm sm:text-xl sm:bg-orange-300 sm:rounded-xl sm:py-7 sm:px-32'>
        <span></span>
      </div>
        <div className='w-full'>
          <AudioPlayer
            className='bg-transparent'
          />
        </div>
      </div>  
  )
}

export default PreloaderQuestionBlock

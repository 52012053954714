import Contact from "../views/Contact";
import MainView from "../views/MainView";
import Media from "../views/Media";
import Post from "../views/Post";
import QandA from "../views/QandA";
import Topic from "../views/Topic";
import TopicDetail from "../views/TopicDetail";
import AllQuestions from "../components/pages/AllQuestions";
import Home from "../components/pages/Home";
import Questions from "../components/pages/Questions";
import SearchQuestions from "../components/pages/SearchQuestions";
import TopicView from "../components/views/Q&A/TopicView";
import AuthView from "../views/auth/AuthView";
import AllAnalysisBiblie from "../components/views/BibleAnalysis/AllAnalysisBiblie";
import SearchBible from "../components/views/BibleAnalysis/SearchBible";
import TopicBible from "../components/views/BibleAnalysis/TopicBible";



let routes = [
  {
    path: "/contact",
    component: Contact,
    layout: "main",
  },
  // {
  //   path: "/bible",
  //   component: Bible,
  //   layout: "main",
  // },
  {
    path: "/posts",
    component: Post,
    layout: "main",
  },
  {
    path: "/auth",
    component: AuthView,
    layout: "auth",
  },
  // {
  //   path: "/",
  //   component: MainView,
  //   layout: "main",
  // },
  {
    path: "/home",
    component: Home,
    layout: 'main'
  },
  {
    path: "/topic",
    component: Questions,
    layout: "main",
  },
  {
    path: '/all-questions',
    component: AllQuestions,
    layout: 'main'
  },
  {
    path: '/search-questions',
    component: SearchQuestions,
    layout: 'main'
  },
  {
    path: "/topic-detail/:id",
    component: TopicView, 
    layout: "main",
  },
  {
    path: '/all-bible',
    component: AllAnalysisBiblie,
    layout: 'main'
  },
  {
    path: '/topic-bible',
    component: TopicBible,
    layout: 'main'
  },
  {
    path: '/search-bible',
    component: SearchBible,
    layout: 'main'
  },
  

];
export default routes;

import React from 'react'

const LoadMoreBtn = ({isLoading, handleLoadMore}) => {
  return (
    <div className='w-full flex items-center justify-center'>
      {!isLoading ? (  
        <button className='p-1 m-3 rounded-xl text-xl sm:text-2xl bg-orange-200' onClick={handleLoadMore} type='submit'>
          Загрузить больше...
        </button>
      ) : (
        <div className='text-xl w-full p-1 text-center m-3 rounded-lg animate-pulse bg-orange-200'>
          Загрузка...
        </div>
      )}
    </div>
  )
}

export default LoadMoreBtn

import React from 'react'

const PhotoBiography = () => {
  return (
    <div className='w-1/3 sm:w-2/5 flex items-center justify-start ml-4 sm:justify-end'>
      <img 
        className='w-full sm:w-2/4 rounded-xl shadow-2xl shadow-black'
        src="pavel_ostrovski2.jpg" 
        alt="pavel_ostrovski2.jpg" 
      />
    </div>
  )
}

export default PhotoBiography

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { getQuestionsByTopic } from '../../../store/actions/questionActions';
import QuestionBlock from './QuestionBlock';
import PreloaderQuestionBlock from '../../loaders/PreloaderQuestionBlock';
import {Pagination} from '@mui/material'
import NothingNotFound from '../../component/NothingNotFound';

const TopicView = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  const { questionsByTopic, isGettingQuestions } = useSelector((state) => state.quest);

  const [currentPage, setCurrentPage] = useState(1)
  const [questionsByTopicS, setQuestionsByTopicS] = useState([])
  const itemsPerPage = 10
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = questionsByTopicS.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  useEffect(() => {
    if (data === undefined) 
      history.push("/topic")
  }, [data]);

  useEffect(() => {
    if (data !== undefined) 
      dispatch(getQuestionsByTopic(data.topicId, data.topicname))
  }, [data]);

  useEffect(() => {
    const sortedQuestionByTopic = questionsByTopic.sort((a, b) => a.create_At.seconds - b.create_At.seconds)
    setQuestionsByTopicS(sortedQuestionByTopic)
  }, [questionsByTopic])

  return (
    <div className='flex items-center justify-center' style={{minHeight: '100vh'}}>
        <div className='flex w-11/12 sm:w-1/2 flex-col items-center justify-center mt-10 sm:mt-20'>

          {questionsByTopicS.length === 0 && !isGettingQuestions && (
            <NothingNotFound/>
          )}

          {questionsByTopicS.length === 0 || isGettingQuestions ? (
            <>  
              <PreloaderQuestionBlock/>
              <PreloaderQuestionBlock/>
              <PreloaderQuestionBlock/>
            </>
          ) : (
            <>
              {currentItems.map((question) => (
                <QuestionBlock key={question.id} questionData={question} />
              ))}

              <Pagination
                className='m-4'
                count={Math.ceil(questionsByTopic.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                size='large'
                color='secondary'
                variant='outlined'
              />
              
            </>
          )}

        </div>
    </div>
  )
}

export default TopicView

import {
    Grid,
    Stack,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Chip,
} from "@mui/material";

import React, { useEffect } from "react";

import moment from "moment";

import { useSelector } from "react-redux";

import { useState } from "react";
// import LoadingOverlay from "react-loading-overlay";

const QuestionCard = ({ allQuestions, questionsByTopic, bibleQuestions }) => {
    const { isGettingQuestions } = useSelector((state) => state.quest);
    const [filterquestions, setfilterquestions] = useState([]);
    // useEffect(() => {
    //     if (postQueryParam === "search") {
    //         console.log("search is true");
    //         dispatch(getAllQuestion());
    //     }
    // }, []);
    useEffect(() => {
        if (allQuestions && allQuestions.length > 0) {
            setfilterquestions(allQuestions);
        } else if (questionsByTopic && questionsByTopic.length > 0) {
            setfilterquestions(questionsByTopic);
        } else if (bibleQuestions && bibleQuestions.length > 0) {
            setfilterquestions(bibleQuestions);
        } else {
            setfilterquestions([]);
        }
    }, [questionsByTopic, allQuestions, bibleQuestions]);

    // console.log(questionsByTopic)
    
    return (
        <Grid item xs={12} sm={12}>
            {/* <LoadingOverlay
                active={isGettingQuestions}
                spinner
                className="loading-overlay-text"
                text={""}
            > */}
                {filterquestions.length > 0 ? (
                    filterquestions.map((ques) => {
                        // console.log(ques, "ques");
                        return (
                            <Card
                                className="card-bg"
                                marginY={5}
                                sx={{
                                    maxWidth: "100%",
                                    border: "1px solid lightgrey",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            >
                                <CardActionArea>
                                    <CardContent>
                                        {bibleQuestions ? (
                                            ""
                                        ) : (
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} sm={6}>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        key={ques.topicId}
                                                    >
                                                        {ques.topic}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    textAlign="right"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                        justifyContent="flex-end"
                                                    >
                                                        {}
                                                        <Chip
                                                            label={
                                                                ques.quetionNum
                                                            }
                                                            className="bg-white"
                                                        />
                                                        <Chip
                                                            className="bg-white"
                                                            key={ques.id}
                                                            label={ques.chatNum}
                                                            sx={{ ml: "auto" }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Typography
                                            variant={
                                                bibleQuestions ? "h6" : "body2"
                                            }
                                            color="text.secondary"
                                            marginY={3}
                                            className="d-inline-block"
                                        >
                                            {ques.questionContent}
                                        </Typography>
                                        {bibleQuestions ? (
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                justifyContent="flex-end"
                                                className="d-inline-block float-right mt-4"
                                            >
                                                {}

                                                <Chip
                                                    className="bg-white"
                                                    key={ques.id}
                                                    label={ques.chatNum}
                                                    sx={{ ml: "auto" }}
                                                />
                                            </Stack>
                                        ) : (
                                            ""
                                        )}

                                        <div>
                                            <audio controls>
                                                <source
                                                    key={ques.id}
                                                    src={ques.audioFileUrl}
                                                    type="audio/mpeg"
                                                />
                                            </audio>
                                        </div>
                                        <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            marginBottom={3}
                                            sx={{
                                                fontWeight: "bold",
                                                float: "right",
                                            }}
                                        >
                                            {moment
                                                .unix(ques.create_At.seconds)
                                                .format("YYYY-MM-DD")}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        );
                    })
                ) : (
                    <Card
                        className="card-bg"
                        marginY={5}
                        sx={{
                            maxWidth: "100%",
                            border: "1px solid lightgrey",
                            marginTop: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        <CardActionArea>
                            <CardContent className="py-4">
                                <Typography
                                    variant="h6"
                                    color="text.secondary"
                                    marginBottom={3}
                                    className="text-center my-2"
                                >
                                    {isGettingQuestions
                                        ? "Data Loading"
                                        : "Data not found!"}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
            {/* </LoadingOverlay> */}
        </Grid>
    );
};

export default QuestionCard;

import React from 'react'
import FullScreenVideo from '../../component/FullScreenVideo'
import BlockOverlayVideo from './BlockOverlayVideo'
import MainSectionLabelBlock from './MainSectionLabelBlock'


const MainVideoSection = () => {
  return (
      <section className='w-full h-screen relative text-white bg-black' style={{boxShadow: '0px 5px 10px black'}} id='main-section'>
        <div className='w-full h-full relative'>
          <FullScreenVideo/>
          <BlockOverlayVideo>   
            <MainSectionLabelBlock/>
          </BlockOverlayVideo>
        </div>
    </section>
  )
}

export default MainVideoSection

import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Box,
    InputBase,
} from "@mui/material";
import QuestionCard from "../components/QuestionCard";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "@mui/icons-material/Search";
import { withRouter, useLocation } from "react-router-dom";
import {
    getSearchAllQuestion,
    getCompleteQuestion,
} from "../store/actions/questionActions";
import { Button, Spinner } from "reactstrap";

const Post = () => {
  const location = useLocation();
    const dispatch = useDispatch();
    const {
        questions,
        isGettingQuestions,
        allQuestions,
        allLastDocument,
        allActive,
    } = useSelector((state) => state.quest);
    const queryParams = new URLSearchParams(location.search);
    const postQueryParam = queryParams.get("post");
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredQuestions, setFilteredQuestions] = useState(questions);
    const [selectedTopic, setSelectedTopic] = React.useState("All");
    const [lastDocuments, setLastDocuments] = useState(null);
    const [topicDropDown, setTopicDropDown] = useState([]);
    const handleSearch = () => {
        setSelectedTopic("All");
        if (searchTerm !== "") {
            const filtered = questions.filter((question) =>
                question.questionContent
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
            setFilteredQuestions(filtered);
        } else {
            setFilteredQuestions(questions);
        }
    };
    const searchTopicByName = (event) => {
        setSearchTerm("");
        setSelectedTopic(event.target.value);
        // console.log(event.target.value, "event.target.value");
        if (event.target.value != "" && event.target.value != "All") {
            const filtered = questions.filter((question) =>
                question.topic.includes(event.target.value)
            );
            setFilteredQuestions(filtered);
        } else if (event.target.value == "All") {
            setFilteredQuestions(questions);
        } else {
            setFilteredQuestions(questions);
        }
    };
    const handleLoadMore = () => {
        dispatch(getCompleteQuestion(lastDocuments));
    };
    useEffect(() => {
        setLastDocuments(allLastDocument);
    }, [allLastDocument]);
    useEffect(() => {
        if (postQueryParam === "search") {
            // console.log("search is true");
            dispatch(getSearchAllQuestion());
        } else {
            dispatch(getCompleteQuestion(null));
        }
    }, []);
    useEffect(() => {
        setFilteredQuestions(allQuestions);
    }, [allQuestions]);
    useEffect(() => {
        setFilteredQuestions(questions);
        const getUniqueTopics = (questionsArray) => {
            const uniqueTopicsMap = new Map();
            questionsArray.forEach((question) => {
                const { id, topic } = question;
                if (!uniqueTopicsMap.has(topic)) {
                    uniqueTopicsMap.set(topic, id);
                }
            });
            return uniqueTopicsMap;
        };
        const uniqueTopicsMap = getUniqueTopics(questions);
        const topicDropDownOptions = Array.from(uniqueTopicsMap).map(
            ([topic, id]) => ({
                id,
                topic,
            })
        );
        setTopicDropDown(topicDropDownOptions);
    }, [questions]);

    return (
        <Container>
            {location.pathname === "/posts" &&
            postQueryParam === "all" ? null : (
                <Grid
                    container
                    sx={{
                        border: "1px solid #ccc",
                        boxShadow: "0 2px 4px rgba(0,0,0,.2)",
                        padding: 2,
                        marginY: 3,
                        display: "flex",
                        justifyContent: "center",
                    }}
                    alignItems="center"
                >
                    {location.pathname === "/posts" &&
                    postQueryParam !== "topic" ? (
                        <Grid item md={7} sm={7} sx={12}>
                            <Box
                                component="form"
                                sx={{
                                    backgroundColor: "#F5F5F5",
                                    borderRadius: "10px",
                                    position: "relative",
                                    display: "flex",
                                    paddingY: 0.7,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "auto",
                                }}
                            >
                                <IconButton
                                    type="button"
                                    sx={{}}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Поиск по словам"
                                    inputProps={{
                                        "aria-label": "Search Users",
                                    }}
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                />
                            </Box>
                        </Grid>
                    ) : null}
                    <Grid item md={3}>
                        {location.pathname.startsWith("/posts") &&
                        postQueryParam === "search" &&
                        isGettingQuestions == false ? (
                            <FormControl
                                sx={{ m: 1, minWidth: 120 }}
                                size="small"
                                className="float-right"
                            >
                                <InputLabel id="demo-select-small-label">
                                    Topic
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={selectedTopic}
                                    label="Topic"
                                    onChange={searchTopicByName}
                                >
                                    <MenuItem value="All">
                                        <em>All</em>
                                    </MenuItem>
                                    {topicDropDown &&
                                        topicDropDown.map((ques) => (
                                            <MenuItem
                                                key={ques.id}
                                                value={ques.topic}
                                            >
                                                {ques.topic}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        ) : null}
                    </Grid>
                    {location.pathname === "/posts" &&
                    postQueryParam !== "topic" ? (
                        <Grid item md={2}>
                            <Button
                                variant="contained"
                                className="btn-color"
                                disabled={
                                    isGettingQuestions || searchTerm == ""
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    handleSearch();
                                }}
                                size="large"
                                sx={{ minWidth: 120 }}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            )}
            <Grid container spacing={3}>
                <QuestionCard allQuestions={filteredQuestions} />
                {location.pathname === "/posts" &&
                    postQueryParam !== "topic" &&
                    postQueryParam !== "search" && (
                        <Grid item xs={12} sm={12} textAlign="right">
                            {isGettingQuestions ? (
                                ""
                            ) : (
                                <Button
                                    onClick={handleLoadMore}
                                    className="btn-color mb-2 pb-3"
                                    disabled={allActive ? false : true}
                                >
                                    Загрузи больше...
                                </Button>
                            )}
                        </Grid>
                    )}
            </Grid>
        </Container>
    );
};

export default withRouter(Post);

import {
    Email,
    Facebook,
    Instagram,
    LinkedIn,
    LocationOn,
    Phone,
    ThumbUpAlt,
    Twitter,
} from "@mui/icons-material";
import {
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import Map from "../components/Map";
import { Button, Form, Spinner } from "reactstrap";
import { sendContactEmail } from "../store/actions/sendEmailAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Contact = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.sendEmail);
    const [error, setError] = useState("");
    const [contactDetail, setContactDetail] = useState({
        name: "",
        email: "",
        message: "",
    });
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const updatedContactDetails = {
            ...contactDetail,
            [name]: value,
        };
        setContactDetail(updatedContactDetails);
        if (name === "email" && value !== "") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                setError("Invalid email address");
            } else {
                setError("");
            }
        }
    };
    return (
        <div>
            <Container>
                <Grid container>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">
                                    Контактная Информация
                                </Typography>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <LocationOn />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            Khram Georgiya Pobedonostsa V
                                            Nakhabino
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Phone />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            +7 495 566-33-97
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Email />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            example@example.com
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} sm={12}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <ThumbUpAlt className="mt-n1"/>
                                    </Grid>
                                    <Grid item className="pt-2">
                                        <IconButton>
                                            <Facebook className="text-dark"/>
                                        </IconButton>
                                        <IconButton>
                                            <LinkedIn className="text-dark"/>
                                        </IconButton>
                                        <IconButton>
                                            <Twitter className="text-dark"/>
                                        </IconButton>
                                        <IconButton>
                                            <Instagram className="text-dark" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} sm={12} sx={12} className="my-1">
                            <Map />
                        </Grid>
                    </Grid>

                    <Grid item md={6} sx={{ textAlign: "right" }}>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (error == "") {
                                    dispatch(
                                        sendContactEmail(contactDetail, () => {
                                            setContactDetail({
                                                name: "",
                                                email: "",
                                                message: "",
                                            });
                                        })
                                    );
                                } else {
                                    alert("Please enter the valid email!");
                                }
                            }}
                        >
                            {/* <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <TextField
                                        label="Имя"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="Фамилия"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid> */}
                            <TextField
                                label="Имя"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="name"
                                value={contactDetail.name}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                required
                            />
                            <TextField
                                label="Имейл"
                                variant="outlined"
                                margin="normal"
                                name="email"
                                value={contactDetail.email}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                fullWidth
                                required
                            />
                            {error && (
                                <span className="text-danger float-left">{error}</span>
                            )}
                            <TextField
                                label="Ваше сообщение"
                                multiline
                                rows={4}
                                variant="outlined"
                                margin="normal"
                                name="message"
                                value={contactDetail.message}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                fullWidth
                                required
                            />
                            <Button
                                type="submit"
                                className="btn-color"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Spinner size="sm" />
                                ) : (
                                    `${" Отправить"}`
                                )}
                            </Button>
                        </Form>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Contact;

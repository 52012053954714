import React from 'react'
import QASecitonBlock from '../Q&A/QASecitonBlock'
import { bibleblocks } from '../../constants/data'

const BibleAnalysisSection = () => {
  return (
    <section className='w-full md:h-screen flex flex-col items-center justify-center my-20 md:mt-0 md:my-0' id='bible-section'>
    <div className='font-comforta-700 text-lg sm:text-5xl h-1/6'>
      <h1>Разбор Библии</h1>
    </div>
    <div className='w-full flex flex-wrap items-center justify-center space-x-0 md:space-x-5 lg:space-x-20'>
     {bibleblocks.map((block, index) => <QASecitonBlock key={index} {...block}/>)}
    </div>
  </section>
  )
}

export default BibleAnalysisSection

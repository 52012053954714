import React from 'react'
import MailToLink from '../../components/views/SendEmail/MailToLink'
import { BsFillGeoAltFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";
import { socialmedia } from '../../components/constants/data';

const Footer = () => {
  return (
    <footer className='w-full h-36 sm:h-56 flex items-center justify-center sm:space-x-60 bg-orange-200 shadow-2xl shadow-orange-200' >

      <div className='flex justify-center h-full flex-col text-xs sm:text-xl space-y-4 ml-2'>
        <div className='flex items-center text-xs sm:text-xl space-x-3'>
          <div><BsFillGeoAltFill /></div>
          <span>г.о. Красногорск, пос. Нахабино, улица Инженерная, д. 7</span>
        </div>
        <div className='flex items-center space-x-3'>
          <div><FaPhone/></div>
          <span>+7 495 566-33-97</span>
        </div>  
        <MailToLink/>   
      </div>

      <div className='flex flex-wrap items-center justify-center sm:space-x-10'>
        {socialmedia.map((Media, index) => (
          <a className='hover:text-gray-100 transition text-5xl m-1 sm:text-6xl duration-500 ease-in-out ' href={Media.link} target='_blank' key={index} rel="noreferrer">
             <div className='  flex items-center justify-center rounded-full overflow-hidden w-12 h-12'>
            <Media.iconElement />
            </div> 
          </a>
        ))}   
      </div>
    </footer>
  )
}

export default Footer

import React from 'react'
import AllQuestions from '../../pages/AllQuestions'

const AllAnalysisBiblie = () => {
  return (
    <AllQuestions isSearchPage={false} isBiblePage={true} />
  )
}

export default AllAnalysisBiblie

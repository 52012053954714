import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom' 

const QASecitonBlock = ({name, img, desc, link}) => {
  return (
  <Link to={link} className='w-3/4 h-64 sm:w-96 sm:h-96 flex flex-col items-start shadow-xl mt-4 rounded-lg hover:text-black scale-bg-animation'>
      <div className='w-full h-96 overflow-hidden rounded-t-lg'>
        <img 
          className='h-full w-full'
          src={img} 
          alt={img.name} 
        />
      </div>

      <div className='bg-orange-200 h-1/3 p-2 w-full rounded-b-lg'>
        <h1 className='text-lg sm:text-2xl font-comforta-700'>{name}</h1>
        <p className='text-xs whitespace-pre-line w-full sm:text-lg sm:w-96 p-1'>{desc}</p>
      </div>
    </Link>
  )
}

export default QASecitonBlock

import React from 'react'

const BlockOverlayVideo = ({children}) => {
  return (
    <div className="text-overlay absolute top-1/2 left-1/4 translate-x-1/2" style={{transform: 'translate(-30%, -50%)'}}>
      {children}
    </div>
  )
}

export default BlockOverlayVideo
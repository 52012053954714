const initialData = {
    questions: [],
    allQuestions: [],
    allQuestionsSearch: [],
    allLastDocument: {},
    isGettingQuestions: false,
    isGettingQuestionsForSearch: false,
    questionsByTopic: [],
    bibleQuestions: [],
    lastDocument: {},
    active: true,
    allActive: true,
};

const questionReducer = (state = initialData, action) => {
    switch (action.type) {
        // case "FETCH_QUESTION":
        //     return {
        //         ...state,
        //         questions: [...action.payload],
        //     };
        // case "LOAD_QUESTION_MORE":
        //     return {
        //         ...state,
        //         questions: state.questions.concat(action.payload),
        //     };
        case "FETCH_All_QUESTION":
            return {
                ...state,
                questions: action.payload,
            };

        case "FETCH_ALL_QUESTIONS_FOR_SEARCH":
          return {
            ...state,
            allQuestionsSearch: action.payload
          }
        case "LOAD_QUESTION_MORE":
            return {
                ...state,
                allQuestions: action.payload,
                allLastDocument: action.lastDocument,
                allActive: action.active,
            };
        case "BIBLE_QUESTIONS":
            return {
                ...state,
                bibleQuestions: action.payload,
                lastDocument: action.lastDocument,
                active: action.active,
            };
        case "SET_GET_QUESTIONS_LOADER":
          return { ...state, isGettingQuestions: action.payload };
        case "SET_GET_QUESTIONS_FOR_SEARCH_LOADER":
          return { ...state, isGettingQuestionsForSearch: action.payload };
        case "SEARCH_QUESTION":
            return {
                ...state,
                questions: action.payload,
            };
        case "FETCH_QUESTIONS_BY_TOPIC":
            return {
                ...state,
                questionsByTopic: action.payload,
            };
        default:
            return state;
    }
};

export default questionReducer;

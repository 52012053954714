// import firebase from "firebase/app";
// import firebase from 'firebase';
import firebase from "firebase";

// import "firebase/firestore";
// import "firebase/auth";
// import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDT7bqH0vfHoR20DJsRTRy3ex0f141LL5M",
    authDomain: "ostrovski-database.firebaseapp.com",
    databaseURL:
        "https://ostrovski-database-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ostrovski-database",
    storageBucket: "ostrovski-database.appspot.com",
    messagingSenderId: "453190302395",
    appId: "1:453190302395:web:19ffe22bfae480a3d2879e",
    measurementId: "G-X5CZN17X3W",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;

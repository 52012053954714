import AllQuestions from "./AllQuestions"
import React from 'react'


const SearchQuestions = () => {
  return (
    <AllQuestions isSearchPage={true}/>
  )
}

export default SearchQuestions

// Root Reducer
import { combineReducers } from "redux";

import authUserReducer from "./authUser";
import questionReducer from "./questionReducer";
import topicsReducer from "./topicsReducer";
import sendEmailReducer from "./sendEmailReducer"


export let rootReducer = combineReducers({
  authUser: authUserReducer,
  topics: topicsReducer,
  quest: questionReducer,
  sendEmail:sendEmailReducer,
});

export default rootReducer;

import React from 'react'
import { PiCross } from "react-icons/pi";

const CrossPreload = () => {
  return (
    <div className='absolute top-1/2 left-1/2 text-6xl sm:text-9xl -translate-x-1/2 -translate-y-1/2 animate-pulse font-oswald-300 flex flex-col items-center'>
      <div><PiCross/></div>
      <span className='text-3xl sm:text-6xl'>
        Загрузка...
      </span>
    </div>
  )
}

export default CrossPreload

import React from 'react';

const TopicBlock = ({ name, onClick }) => {

  return (
    <div className='scale-text-animation'>
      <button className='bg-orange-300 w-full h-full rounded-xl p-4 text-center transition duration-300 ease-in-out hover:bg-orange-400' onClick={onClick}>
        <span>{name}</span>
      </button>  
    </div>
  );
};

export default TopicBlock;


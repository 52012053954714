import React from 'react'

const SocialMediaBlock = ({name, link, icon, followers}) => {
  return (

<a 
  href={link} 
  target='_blank' 
  rel="noreferrer" 
  className='
    bg-orange-200 rounded-xl transition duration-300 ease-in-out
    hover:bg-orange-300 p-3 font-comforta-700 shadow-lg shadow-black
    flex flex-col items-center justify-center w-3/4 xl:w-72 xl:h-72 
  '
   
>
  <div className='w-32 h-32 sm:w-40 sm:h-40 flex items-center justify-center'>
    <img className='w-full h-full object-cover p-4' src={icon} alt="" />
  </div>
  <div className='text-xs sm:text-xl flex flex-col items-center space-y-1'>
    <h1 className="text-center">@{name}</h1>
    <span className='text-red-500'>{followers}</span>
  </div>    
</a>

    // <a 
    //   href={link} 
    //   target='_blank' 
    //   rel="noreferrer" 
    //   className='
    //     bg-orange-200 rounded-xl transition duration-300 ease-in-out
    //     hover:bg-orange-300 p-3 font-comforta-700 shadow-lg shadow-black
    //      flex flex-col items-center justify-center w-3/4 xl:w-auto
    //   '
    // >
    //   <div className='w-32 h-32 sm:w-40 sm:h-40 flex items-center justify-center'>
    //     <img className='w-full h-full object-cover p-4' src={icon} alt="" />
    //   </div>
    //   <div className='text-xs sm:text-xl flex items-center space-x-2'>
    //     <h1 className='text-wrap'>@{name}</h1>
    //     <span className='text-red-500'>{followers}</span>
    //   </div>    
    // </a>
  )
}

export default SocialMediaBlock

import React, { useEffect, useState } from 'react'

const FullScreenVideo = () => {
  const [videoSource, setVideoSource] = useState(null);

  useEffect(() => {
    // Після завантаження компонента, починаємо завантаження відео
    const fetchVideo = async () => {
      try {
        const response = await fetch('preview.mp4'); // Завантаження відео
        if (response.ok) {
          const blob = await response.blob(); // Отримання відео як blob
          const videoUrl = URL.createObjectURL(blob); // Створення URL для відео
          setVideoSource(videoUrl); // Збереження URL в стані
        } else {
          throw new Error('Не вдалося завантажити відео');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchVideo();
  }, []);


  return (
    <>
    {videoSource && (
      <video autoPlay muted loop style={{ width: '100%', height: '100%', objectFit: 'cover' }} preload='metadata'>
        <source src={videoSource} type='video/mp4' />
      </video>
    )}
    </>
  )
}

export default FullScreenVideo

import firebase from "../../config/firebase";

export const getTopics = () => async (dispatch) => {
    dispatch(topicLoader(true));
    try {
        firebase
            .firestore()
            .collection("Topics")
            .limit(50)
            .onSnapshot(async (query) => {
                let topicData = [];
                for (let doc of query.docs) {
                    topicData.push({ id: doc.id, ...doc.data() });
                }
                dispatch({ type: "FETCH_TOPICS", payload: topicData });
                dispatch(topicLoader(false));
            });
    } catch (error) {
        console.error("Error fetching All questions: ", error);
        dispatch(topicLoader(false));
    }
};
export const topicLoader = (val) => async (dispatch) => {
    dispatch({ type: "TOPIC_LOADER", payload: val });
};

export const topicClear = () => (dispatch) => {
  dispatch({ type: "TOPIC_CLEAR", payload: [] })
}
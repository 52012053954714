import React, { useState } from 'react'
import { IoMdMail } from "react-icons/io";
import { IoMailOpenSharp } from "react-icons/io5";


const EmailIconOpening = ({isHovered}) => {
  return (
    <>
      {isHovered ? (
        <IoMailOpenSharp />
      ) : (
        <IoMdMail/>
      )}
    </>
  )
}

export default EmailIconOpening

import React from 'react'

const MainSectionLabelBlock = () => {
  const scrollNext = () => {
    const screenHeight = window.innerHeight
    window.scrollBy({
      top: screenHeight,
      behavior: 'smooth'
    })
  }

  return (
    <div className='p-3 bg-transparent flex flex-col items-start justify-between space-y-6'>
      <div className=''>
        <h1 className='text-2xl sm:text-7xl text-white font-comforta-700 whitespace-nowrap'>
          {"Павел Островский"}
        </h1>
      </div>
      <div>
        <p className='whitespace-pre-line text-sm sm:text-2xl font-comforta-700 font-normal'>
          
          {"Священник Православной Церкви, телеведущий, \n автор, интернет-миссионер"}
        </p>
      </div>
      <div className=''>
        <button className='bg-orange-600 rounded-lg p-1 text-sm sm:p-2 sm:text-xl select-none transition duration-300 ease-in-out hover:bg-orange-700' onClick={scrollNext}>
          Дальше
        </button>
      </div>
    </div>  
  )
}

export default MainSectionLabelBlock

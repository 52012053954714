import React from 'react'
import { FaMagnifyingGlass } from "react-icons/fa6";

const SearchQuestionBlock = ({handleSearch, handleSearchTerm, searchTerm, isLoading}) => {
  return (
    <div className={`mt-10 sm:mt-20 w-full mb-2 bg-orange-200 rounded-xl p-2 sm:p-6 flex items-center space-x-3 justify-center ${isLoading && 'opacity-60 animate-pulse'}`} >
      <input
        className='border-0 outline-none w-11/12 p-2 text-lg sm:text-xl rounded-xl'
        type="text"
        value={!isLoading ? searchTerm : 'Загрузка...'}
        onChange={e => handleSearchTerm(e.target.value)}
        disabled={isLoading}
			/>
      <div>
        <button disabled={isLoading} className='p-2.5 rounded-xl text-xl sm:text-2xl bg-orange-300 transition duration-300 ease-in-out hover:bg-orange-400' onClick={handleSearch}>
          <FaMagnifyingGlass/>
        </button> 
      </div>
    </div>
  )
}

export default SearchQuestionBlock

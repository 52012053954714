const initialData = {
    topic: [],
    topicLoader: false,
};

const topicsReducer = (state = initialData, action) => {
    switch (action.type) {
        case "FETCH_TOPICS":
            return {
                ...state,
                topic: action.payload,
            };
        case "TOPIC_LOADER":
            return {
                ...state,
                topicLoader: action.payload,
            };
        case "TOPIC_CLEAR":
          return {
            ...state,
            topicClear: action.payload
          }
        default:
            return state;
    }
};

export default topicsReducer;

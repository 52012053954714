import React, { useEffect } from 'react'
import { qablocks } from '../../constants/data'
import QASecitonBlock from './QASecitonBlock'
import { useDispatch } from 'react-redux';
import { clearBibleQuestion, clearCompleteQuestion, clearSearchAllQuestion } from "../../../store/actions/questionActions";

const QASection = () => {
  const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(clearCompleteQuestion())
        dispatch(clearSearchAllQuestion())
        dispatch(clearBibleQuestion())
    },[])

  return (
    <section className='w-full lg:h-screen flex flex-col items-center justify-center my-20 sm:mt-0 sm:my-0' id='q&a-section'>
      <div className='font-comforta-700 text-lg sm:text-5xl h-1/6'>
        <h1>Вопрос/Ответ</h1>
      </div>
      <div className='w-full flex flex-wrap items-center justify-center space-x-0 lg:space-x-20'>
       {qablocks.map((block, index) => <QASecitonBlock key={index} {...block}/>)}
      </div>
    </section>
  )
}

export default QASection

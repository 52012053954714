import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-scroll'

const NavLink = ({name, sectionId}) => {
  const history = useHistory()

  const scrollToSection = () => {
    history.push('/home', {sectionId: sectionId})

    const section = document.getElementById(sectionId)
    const yOffset = -50;

    if (section) {
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    }
  }
 
  return (
    <Link to={sectionId} smooth={true} duration={500}>
      <button  
        className='
          text-xs sm:text-xl font-oswald-300 p-1 py-2 transition duration-75 ease-in-out
          rounded-md cursor-pointer select-none 
        '
        onClick={scrollToSection}
      >
        <span className='scale-text-animation text-white'>{name}</span>
      </button>
    </Link>
  )
}

export default NavLink

import React, { useEffect, useState } from 'react'
import WelcomeSection from '../views/Welcome/WelcomeSection'
import MainVideoSection from '../views/Home/MainVideoSection'
import AboutMeSeciton from '../views/AboutMe/AboutMeSeciton'
import QASection from '../views/Q&A/QASection'
import SocialMediaSection from '../views/SocialMedia/SocialMediaSection'
import LastYouTubeVideosSection from '../views/LastYouTubeVideos/LastYouTubeVideosSection'
import SendEmailSection from '../views/SendEmail/SendEmailSection'
import BibleAnalysisSection from '../views/BibleAnalysis/BibleAnalysisSection'
import { useHistory } from 'react-router-dom'

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    const { state } = history.location;
    const sectionId = state && state.sectionId;

    const scrollToSection = (id) => {
      const section = document.getElementById(id);
      const yOffset = -100;

      if (section) {
        const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    if (sectionId) {
      scrollToSection(sectionId);
    }
  }, [history]);


 

  return (
    <div className='w-full h-full flex flex-col items-center justify-center'>
      <MainVideoSection/>
      <WelcomeSection/>
      <AboutMeSeciton/>
      <QASection/>
      <BibleAnalysisSection/>
      <SocialMediaSection/>
      <SendEmailSection/>
    </div> 
  )
}

export default Home

import React from 'react'
import { socialmedia } from '../../constants/data'
import SocialMediaBlock from './SocialMediaBlock'

const SocialMediaSection = () => {
  return (
    <section className='w-full h-full sm:h-screen flex flex-col items-center justify-center my-20 sm:my-0'>
      <div className='text-xl sm:text-5xl h-1/6 font-comforta-700 my-5'>
        <h1>Контакты</h1>
      </div>
      <div className='w-full flex flex-col sm:w-3/4 xl:flex-row xl:flex-wrap xl:w-full items-center justify-center space-x-0 space-y-5 xl:space-x-20'>
        {socialmedia.map((media, index) => <SocialMediaBlock key={index} {...media} />)}
      </div>
    </section>
  )
}

export default SocialMediaSection 

import React, { useState } from 'react'
import EmailIconOpening from '../../component/EmailIconOpening'

const MailToLink = () => {
  const [isHovered, setIsHoveres] = useState(false)

  return (
    <a 
      href={`mailto:pavelostrovski@inbox.ru`} 
      className='flex items-center text-sm sm:text-xl space-x-2'
      onMouseEnter={() => setIsHoveres(true)}
      onMouseLeave={() => setIsHoveres(false)}
    >
      <EmailIconOpening isHovered={isHovered}/>
      <span>pavelostrovski@inbox.ru</span>
    </a>
  )
}

export default MailToLink

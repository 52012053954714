import React, { useState } from 'react'
import {Grid, IconButton, TextField} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { sendContactEmail } from './../../../store/actions/sendEmailAction';
import { Button, Form, Spinner } from "reactstrap";

const FormSendEmail = () => {

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sendEmail);

  
  const [error, setError] = useState("");
  const [contactDetail, setContactDetail] = useState({
      name: "",
      email: "",
      message: "",
  });

  const handleChange = (e) => {
      e.preventDefault();
      
      const { name, value } = e.target;
      const updatedContactDetails = {
        ...contactDetail,
        [name]: value,
      };
      setContactDetail(updatedContactDetails);
      if (name === "email" && value !== "") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
              setError("Invalid email address");
          } else {
              setError("");
          }
      }
  };

    const onSubmitForm = (e) => {
      e.preventDefault();
      if (!error) {
          dispatch(
            sendContactEmail(
              contactDetail, () => {setContactDetail({name: "", email: "", message: ""});
          })
        );
      } else {
          alert("Please enter the valid email!");
      }
    }

  return (
    <Grid className='w-full flex items-center justify-center' item md={6} sx={{ textAlign: "right" }}>
      <Form className='w-4/5 sm:w-1/3' onSubmit={onSubmitForm}>
        <TextField
          label="Имя"
          variant="outlined"
          margin="normal"
          fullWidth
          name="name"
          value={contactDetail.name}
          onChange={(e) => {
              handleChange(e);
          }}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          name="email"
          value={contactDetail.email}
          onChange={(e) => {
              handleChange(e);
          }}
          fullWidth
          required
        />
        {error && (
          <span className="text-danger float-left">{error}</span>
        )}
        <TextField
          label="Ваше сообщение"
          multiline
          rows={4}
          variant="outlined"
          margin="normal"
          name="message"
          value={contactDetail.message}
          onChange={(e) => {
              handleChange(e);
          }}
          fullWidth
          required
        />
        <Button
          type="submit"
          className="btn-color"
          disabled={loading}
        >
          {loading ? (
              <Spinner size="sm" />
          ) : (
              `${" Отправить"}`
          )}
        </Button>
      </Form>
    </Grid>
  )
}

export default FormSendEmail


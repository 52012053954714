import earphonesPhoto from '../../assets/img/earphones.jpeg'
import foldersPhoto from '../../assets/img/folders.314d90f3.jpeg'
import questionPhoto from '../../assets/img/question.61f45e17.jpeg'
import allBooks from '../../assets/img/all_books.jpg'
import topicBook from '../../assets/img/topic_book.jpg'
import searchBook from '../../assets/img/magnify_book.jpg'
import { FaYoutube, FaInstagramSquare, FaTelegram,FaYoutubeSquare } from "react-icons/fa";

export const links = [
  {
    name: 'Главная',
    sectionId: 'main-section'
  },
  {
    name: 'Вопрос/Ответ',
    sectionId: 'q&a-section'
  },
  {
    name: 'Разбор Библии',
    sectionId: 'bible-section'
  },
  {
    name: 'Контакты',
    sectionId: 'contacts-section'
  },

]

export const qablocks = [
  {
    name: 'Все вопросы',
    img: earphonesPhoto,
    desc: 'Нажмите здесь что-бы посмотреть все вопросы на этой платформе',
    link: '/all-questions'
  },
  {
    name: 'Вопросы по темам',
    img: foldersPhoto,
    desc: 'Нажмите здесь что-бы просмотреть вопросы разбитые по разным темам',
    link: '/topic'
  },
  {
    name: 'Поиск вопросов',
    img: questionPhoto,
    desc: 'Нажмите здесь что-бы найти подходящий вам вопрос, по ключевым словам',
    link: '/search-questions'
  }
]

export const bibleblocks = [
  {
    name: 'Все разборы',
    img: allBooks,
    desc: 'Нажмите здесь что-бы посмотреть все разборы на этой платформе',
    link: '/all-bible'
  },
  {
    name: 'Поиск разборов',
    img: searchBook,
    desc: 'Нажмите здесь что-бы найти подходящий вам разбор, по ключевым словам',
    link: '/search-bible'
  }
]

export const socialmedia = [
  {
    name: 'pavelostrovsk',
    link: 'https://www.youtube.com/@pavelostrovski',
    icon: 'youtube.png',
    followers: '174K',
    iconElement: FaYoutubeSquare
  },  
  {
    name: 'pavelostrovsk',
    link: 'https://www.instagram.com/pavelostrovski/',
    icon: 'instagram.webp',
    followers: '565K',
    iconElement: FaInstagramSquare
  },  
  {
    name: 'Павел Островский',
    link: 'https://t.me/pavelostrovski',
    icon: 'telegram.png',
    followers: '178K',
    iconElement: FaTelegram
  },  
  {
    name: 'Павел Островский | Беседы',
    link: 'https://t.me/pavelostrovskibesedy',
    icon: 'telegram.png',
    followers: '10K',
    iconElement: FaTelegram
  },  
]
import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "./Footer/Footer";

const Main = (props) => {
    return (
        <>
            {/* <Header></Header> */}
            <Navbar/>
            {props.children}
            <Footer/>
            {/* <footer>Main Footer</footer> */}
        </>
    );
};

export default Main;

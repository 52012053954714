import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { getTopics } from '../../store/actions/topicActions';
import { useHistory } from 'react-router-dom';
import TopicBlock from '../views/Q&A/TopicBlock';
import CrossPreload from '../loaders/CrossPreload';


const Questions = ({isBiblePage}) => {
  const dispatch = useDispatch();
    const { topic, topicLoader } = useSelector((state) => state.topics);

    const history = useHistory();

    const handleTopicClick = (topicId,topic) => {
      history.push({ pathname: `/topic-detail/${topicId}`, state: {topicId:topicId,topicname:topic }});
    };

    useEffect(() => {
      dispatch(getTopics());
    }, []);

  return (
    <div className='flex items-center justify-center' style={{minHeight: '80vh'}}>
      <div className='w-full sm:w-1/2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4 font-oswald-300 text-xl sm:text-2xl m-12'>
        {topicLoader && <CrossPreload/>}

        {topic
        .slice()
        .sort((a, b) => {return a.topic.localeCompare(b.topic)})
        .map((topics) => 
          <TopicBlock 
            key={topics.id} 
            name={topics.topic} 
            onClick={() => handleTopicClick(topics.docId, topics.topic)} 
          />
        )}
      </div>
    </div>
  )
}

export default Questions

import React from 'react';

import ReactDOM from 'react-dom';

import App from './App';

// Import CSS here
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/style.css"
import "./index.css"
import "./assets/css/fonts.css"
import "./assets/css/animations.css"


ReactDOM.render(<App />, document.getElementById('root'));
import React from 'react'
import { MdSearchOff } from "react-icons/md";

const NothingNotFound = () => {
  return (
    <div className='w-full flex flex-col items-center font-oswald-300'>
      <MdSearchOff className='text-5xl sm:text-7xl'/>
      <h1 className='text-2xl sm:text-4xl'>Ничего не найдено...</h1>
    </div>
  )
}

export default NothingNotFound

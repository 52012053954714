import React from 'react'
import Questions from '../../pages/Questions'

const TopicBible = () => {
  return (
    <Questions isBiblePage={true}/>
  )
}

export default TopicBible

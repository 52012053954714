import React from 'react'
import BiographyText from './BiographyText'
import PhotoBiography from './PhotoBiography'

const AboutMeSeciton = () => {
  return (
    <section className='w-full h-full my-20 sm:my-0 sm:h-screen flex flex-col items-center justify-center text-black'>
      <div className='font-comforta-700 text-xl sm:text-5xl'>
        <h1>Краткая биография</h1>
      </div>

      <div className='w-full flex items-center justify-center space-x-5 sm:space-x-20 m-5'>
        <PhotoBiography/>
        <BiographyText/>
      </div>

    </section>
  )
}

export default AboutMeSeciton

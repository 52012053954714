import moment from 'moment';
import React, { useEffect, useState } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const QuestionBlock = ({ questionData }) => {
  const [isPaused, setIsPaused] = useState(true);

  useEffect(() => {
    const isPlayerPaused = localStorage.getItem('isPlayerPaused');

    if (isPlayerPaused === null) {
      setIsPaused(true);
    } else {
      setIsPaused(isPlayerPaused === 'true');
    }
  }, []);

  const handleAudioPlay = () => {
    setIsPaused(false);
    localStorage.setItem('isPlayerPaused', 'false');
  };

  const handleAudioPause = () => {
    setIsPaused(true);
    localStorage.setItem('isPlayerPaused', 'true');
  };

  return (
    <div className='  
      w-full bg-orange-200 m-2 rounded-xl flex flex-col items-start 
      space-y-4 justify-between font-oswald-300 p-3
      transition duration-500 ease-in-out hover:bg-orange-300
    '
    >
    <div className='flex items-center w-full justify-between sm:whitespace-nowrap'>
      <h1 className='text-lg sm:text-3xl'>{questionData?.topic}</h1>

      <div className='text-sm sm:text-2xl whitespace-nowrap space-x-1 sm:space-x-3 flex items-center'>
        <span className='p-1 px-2 bg-orange-300 rounded-xl'>{questionData?.quetionNum}</span>
        <span className='p-1 bg-orange-300 rounded-xl'>{questionData?.chatNum}</span>
      </div>
    </div>
    <div className='text-sm sm:text-xl'>
      <span>{questionData?.questionContent}</span>
    </div>
      <div className='w-full'>
        <AudioPlayer
          className='bg-transparent'
          src={questionData?.audioFileUrl}
          autoPlayAfterSrcChange={false}
          onPause={handleAudioPause}
          onPlay={handleAudioPlay}
          autoPlay={!isPaused}
        />
      </div>
    </div>
  );
};

export default QuestionBlock;
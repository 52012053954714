import firebase from "../../config/firebase";

export const setGetQuestionsLoader = (val) => async (dispatch) => {
    dispatch({ type: "SET_GET_QUESTIONS_LOADER", payload: val });
};
export const setGetQuestionsForSearchLoader = (val) => async (dispatch) => {
    dispatch({ type: "SET_GET_QUESTIONS_FOR_SEARCH_LOADER", payload: val });
};

// export const getQuestion = () => async (dispatch) => {
//     dispatch(setGetQuestionsLoader(true));
//     console.log("enter");
//     try {
//         firebase
//             .firestore()
//             .collection("Questions")
//             .onSnapshot(async (query) => {
//                 let questionData = [];
//                 for (let doc of query.docs) {
//                     let topicId = doc.data().topicId;
//                     let topicname = await firebase
//                         .firestore()
//                         .collection("Topics")
//                         .doc(topicId)
//                         .get()
//                         .then((doc) => {
//                             if (doc.exists) {
//                                 return doc.data().topic;
//                             }
//                         });

//                     let chatNumber = doc.data().chatNumber;
//                     let chatNum = await firebase
//                         .firestore()
//                         .collection("Chats")
//                         .doc(chatNumber)
//                         .get()
//                         .then((doc) => {
//                             if (doc.exists) {
//                                 return doc.data().chatNum;
//                             }
//                         });

//                     questionData.push({
//                         id: doc.id,
//                         ...doc.data(),
//                         topicname,
//                         chatNum,
//                     });
//                 }
//                 console.log(questionData, "questionData");
//                 dispatch({ type: "FETCH_QUESTION", payload: questionData });
//                 dispatch(setGetQuestionsLoader(false));
//             });
//     } catch (error) {
//         console.error("Error fetching  questions: ", error);
//         dispatch(setGetQuestionsLoader(false));
//     }
// };
// export const getmoreQuestion = (item) => async (dispatch) => {
//     console.log(item, "item");
//     dispatch(setGetQuestionsLoader(true));
//     firebase
//         .firestore()
//         .collection("Questions")
//         .orderBy("create_At", "desc")
//         .limit(50)
//         .startAfter(item.create_At)
//         .onSnapshot(async (query) => {
//             let questionData = [];
//             for (let doc of query.docs) {
//                 let topicId = doc.data().topicId;
//                 let topicname = await firebase
//                     .firestore()
//                     .collection("Topics")
//                     .doc(topicId)
//                     .get()
//                     .then((doc) => {
//                         if (doc.exists) {
//                             return doc.data().topic;
//                         }
//                     });
//                     console.log(topicname,'topicname')
//                 let chatNumber = doc.data().chatNumber;
//                 let chatNum = await firebase
//                     .firestore()
//                     .collection("Chats")
//                     .doc(chatNumber)
//                     .get()
//                     .then((doc) => {
//                         if (doc.exists) {
//                             return doc.data().chatNum;
//                         }
//                     });

//                 questionData.push({
//                     id: doc.id,
//                     ...doc.data(),
//                     topicname,
//                     chatNum,
//                 });
//             }
//             dispatch({ type: "LOAD_QUESTION_MORE", payload: questionData });
//             dispatch(setGetQuestionsLoader(false));
//         });
// };
export const getSearchAllQuestion = () => async (dispatch) => {
    dispatch(setGetQuestionsLoader(true));
    try {
        const questionsQuerySnapshot = await firebase
            .firestore()
            .collection("Questions")
            .get();

        const questionData = [];

        for (let doc of questionsQuerySnapshot.docs) {
            let topicId = doc.data().topicId;
            let chatNumber = doc.data().chatNumber;

            const [topicDoc, chatDoc] = await Promise.all([
                firebase.firestore().collection("Topics").doc(topicId).get(),
                firebase.firestore().collection("Chats").doc(chatNumber).get(),
            ]);

            let topic = topicDoc.exists ? topicDoc.data().topic : null;
            let chatNum = chatDoc.exists ? chatDoc.data().chatNum : null;

            questionData.push({
                id: doc.id,
                ...doc.data(),
                topic,
                chatNum,
            });
        }

        dispatch({ type: "FETCH_All_QUESTION", payload: questionData });
        dispatch(setGetQuestionsLoader(false));
    } catch (error) {}
};

export const getSearchAllQuestionForSearch = () => async (dispatch) => {
    dispatch(setGetQuestionsForSearchLoader(true));
    try {
        const questionsQuerySnapshot = await firebase
            .firestore()
            .collection("Questions")
            .get();

        const questionData = [];

        for (let doc of questionsQuerySnapshot.docs) {
            let topicId = doc.data().topicId;
            let chatNumber = doc.data().chatNumber;

            const [topicDoc, chatDoc] = await Promise.all([
                firebase.firestore().collection("Topics").doc(topicId).get(),
                firebase.firestore().collection("Chats").doc(chatNumber).get(),
            ]);

            let topic = topicDoc.exists ? topicDoc.data().topic : null;
            let chatNum = chatDoc.exists ? chatDoc.data().chatNum : null;

            questionData.push({
                id: doc.id,
                ...doc.data(),
                topic,
                chatNum,
            });
        }

        dispatch({ type: "FETCH_ALL_QUESTIONS_FOR_SEARCH", payload: questionData });
        dispatch(setGetQuestionsForSearchLoader(false));
    } catch (error) {}
};

export const getSearchQuestionByTerm = (searchTerm) => async (dispatch) => {
  dispatch(setGetQuestionsLoader(true));
  try {
    // Конвертуйте searchTerm до нижнього регістру для нечутливості до регістру
    const searchTermLowerCase = searchTerm

    let questionsRef = firebase.firestore().collection("Questions");
    if (searchTerm) {

      questionsRef = questionsRef.orderBy('questionContent').startAt(searchTermLowerCase).endAt(searchTermLowerCase + '~')
      // Використовуйте метод, що дозволяє пошук по частковому збігу термінів
      // questionsRef = questionsRef.where("questionContent", ">=", searchTermLowerCase)
      //                            .where("questionContent", "<=", searchTermLowerCase + "\uf8ff");
    }

    const questionsQuerySnapshot = await questionsRef.get();

    const questionData = [];

    for (let doc of questionsQuerySnapshot.docs) {
      let topicId = doc.data().topicId;
      let chatNumber = doc.data().chatNumber;

      const [topicDoc, chatDoc] = await Promise.all([
        firebase.firestore().collection("Topics").doc(topicId).get(),
        firebase.firestore().collection("Chats").doc(chatNumber).get(),
      ]);

      let topic = topicDoc.exists ? topicDoc.data().topic : null;
      let chatNum = chatDoc.exists ? chatDoc.data().chatNum : null;

      questionData.push({
        id: doc.id,
        ...doc.data(),
        topic,
        chatNum,
      });
    }

    dispatch({ type: "FETCH_All_QUESTION", payload: questionData });
    dispatch(setGetQuestionsLoader(false));
  } catch (error) {
    console.error("Error fetching questions:", error);
    dispatch(setGetQuestionsLoader(false));
  }
};


export const getSearchBibleQuestionByTerm = (searchTerm) => async (dispatch) => {
  dispatch(setGetQuestionsLoader(true));
  try {
    let questionsRef = firebase.firestore().collection("b_questions");

    if (searchTerm) {
      questionsRef = questionsRef.where("questionContent", ">=", searchTerm).where("questionContent", "<=", searchTerm + "\uf8ff");
    }

    const questionsQuerySnapshot = await questionsRef.get();

    const questionData = [];

    for (let doc of questionsQuerySnapshot.docs) {
      let topicId = doc.data().topicId;
      let chatNumber = doc.data().chatNumber;

      const [topicDoc, chatDoc] = await Promise.all([
        firebase.firestore().collection("Topics").doc(topicId).get(),
        firebase.firestore().collection("Chats").doc(chatNumber).get(),
      ]);

      let topic = topicDoc.exists ? topicDoc.data().topic : null;
      let chatNum = chatDoc.exists ? chatDoc.data().chatNum : null;

      questionData.push({
        id: doc.id,
        ...doc.data(),
        topic,
        chatNum,
      });
    }

    dispatch({
      type: "BIBLE_QUESTIONS",
      payload: questionData,
    });
    dispatch(setGetQuestionsLoader(false));
  } catch (error) {
    console.error("Error fetching questions:", error);
    dispatch(setGetQuestionsLoader(false));
  }
};


export const getQuestionsBiblePagination = (page, itemsPerPage) => async (dispatch) => {
  dispatch(setGetQuestionsLoader(true));
  try {
    const questionsRef = firebase
      .firestore()
      .collection("b_questions")
      .orderBy("create_At", "asc");

    const startAfterDocument =
      page > 1
        ? await questionsRef.limit((page - 1) * itemsPerPage).get()
        : null;

    const questionsQuerySnapshot = startAfterDocument
      ? await questionsRef
          .startAfter(
            startAfterDocument.docs[startAfterDocument.docs.length - 1]
          )
          .limit(itemsPerPage)
          .get()
      : await questionsRef.limit(itemsPerPage).get();

    const questionData = [];

    for (let doc of questionsQuerySnapshot.docs) {
      let topicId = doc.data().topicId;
      let chatNumber = doc.data().chatNumber;

      const [topicDoc, chatDoc] = await Promise.all([
        firebase.firestore().collection("Topics").doc(topicId).get(),
        firebase.firestore().collection("Chats").doc(chatNumber).get(),
      ]);

      let topic = topicDoc.exists ? topicDoc.data().topic : null;
      let chatNum = chatDoc.exists ? chatDoc.data().chatNum : null;

      questionData.push({
        id: doc.id,
        ...doc.data(),
        topic,
        chatNum,
      });
    }

    dispatch({
      type: "BIBLE_QUESTIONS",
      payload: questionData,
  });
    dispatch(setGetQuestionsLoader(false));
  } catch (error) {
    console.error("Error fetching questions:", error);
    dispatch(setGetQuestionsLoader(false));
  }
};
export const getQuestionsPagination = (page, itemsPerPage) => async (dispatch) => {
  dispatch(setGetQuestionsLoader(true));
  try {
      const questionsRef = firebase
          .firestore()
          .collection("Questions")
          .orderBy("create_At", "asc");

      const startAfterDocument = page > 1 ? await questionsRef.limit((page - 1) * itemsPerPage).get() : null;

      const questionsQuerySnapshot = startAfterDocument
          ? await questionsRef.startAfter(startAfterDocument.docs[startAfterDocument.docs.length - 1]).limit(itemsPerPage).get()
          : await questionsRef.limit(itemsPerPage).get();

      const questionData = [];

      for (let doc of questionsQuerySnapshot.docs) {
          let topicId = doc.data().topicId;
          let chatNumber = doc.data().chatNumber;

          const [topicDoc, chatDoc] = await Promise.all([
              firebase.firestore().collection("Topics").doc(topicId).get(),
              firebase.firestore().collection("Chats").doc(chatNumber).get(),
          ]);

          let topic = topicDoc.exists ? topicDoc.data().topic : null;
          let chatNum = chatDoc.exists ? chatDoc.data().chatNum : null;

          questionData.push({
              id: doc.id,
              ...doc.data(),
              topic,
              chatNum,
          });
      }

      dispatch({ type: "FETCH_All_QUESTION", payload: questionData });
      dispatch(setGetQuestionsLoader(false));
  } catch (error) {
      console.error("Error fetching questions:", error);
      dispatch(setGetQuestionsLoader(false));
  }
};

export const getAllQuestionsCount = async () => {
  const questionCollectionRef = firebase.firestore().collection("Questions");
  const questionQuerySnapshot = await questionCollectionRef.get();

  return questionQuerySnapshot.size;
}
export const getAllBibleQuestionsCount = async () => {
  const questionCollectionRef = firebase.firestore().collection("b_questions");
  const questionQuerySnapshot = await questionCollectionRef.get();

  return questionQuerySnapshot.size;
}

export const getCompleteQuestion =
    (item, batchSize) =>
    async (dispatch, getState) => {
        dispatch(setGetQuestionsLoader(true));
        try {
            const collectionRef = firebase.firestore().collection("Questions");
            let query = collectionRef
                .orderBy("create_At", "asc")
                .limit(batchSize);
            if (item && item.lastDocument) {
                query = query.startAfter(item.lastDocument);
            }
            let isFirstFetch = item === null;
            const unsubscribe = query.onSnapshot(async (snapshot) => {
                const questions = [];
                for (const doc of snapshot.docs) {
                    const questionData = doc.data();
                    const chatNumber = questionData.chatNumber;
                    const topicId = questionData.topicId;
                    if (chatNumber) {
                        try {
                            const chatDoc = await firebase
                                .firestore()
                                .collection("Chats")
                                .doc(chatNumber)
                                .get();
                            const chatData = chatDoc.data();
                            if (chatData) {
                                questions.push({
                                    ...questionData,
                                    id: doc.id,
                                    chatNum: chatData.chatNum,
                                });
                            }
                        } catch (error) {
                            console.error("Error fetching chat data: ", error);
                        }
                    } else {
                        questions.push({
                            ...questionData,
                            id: doc.id,
                            chatNum: "",
                        });
                    }
                    if (topicId) {
                        try {
                            const topicDoc = await firebase
                                .firestore()
                                .collection("Topics")
                                .doc(topicId)
                                .get();

                            if (topicDoc.exists) {
                                const topicData = topicDoc.data();
                                questions[questions.length - 1].topic =
                                    topicData.topic;
                            }
                        } catch (error) {
                            console.error("Error fetching topic data: ", error);
                        }
                    }
                }
                const lastDocument =
                    snapshot.docs.length > 0
                        ? snapshot.docs[snapshot.docs.length - 1]
                        : null;
                const newItem = { lastDocument };
                const previousQuestions = getState().quest.allQuestions;
                const newQuestions = questions.filter((question) => {
                    const exists = previousQuestions.some(
                        (prevQuestion) => prevQuestion.id === question.id
                    );
                    return !exists;
                });
                if (isFirstFetch) {
                    isFirstFetch = false;
                    dispatch({
                        type: "LOAD_QUESTION_MORE",
                        payload: questions,
                        lastDocument: newItem,
                        active: true,
                    });
                    dispatch(setGetQuestionsLoader(false));
                } else if (newQuestions.length > 0) {
                    const updatedQuestions = item
                        ? [...previousQuestions, ...questions]
                        : questions;
                    dispatch({
                        type: "LOAD_QUESTION_MORE",
                        payload: updatedQuestions,
                        lastDocument: newItem,
                        active: true,
                    });
                    dispatch(setGetQuestionsLoader(false));
                } else {
                    const updatedQuestions = item
                        ? [...previousQuestions, ...questions]
                        : questions;
                    dispatch({
                        type: "LOAD_QUESTION_MORE",
                        payload: updatedQuestions,
                        lastDocument: newItem,
                        active: false,
                    });
                    dispatch(setGetQuestionsLoader(false));
                    alert("No More All Questions");
                }
            });
            return () => unsubscribe();
        } catch (error) {
            console.error("Error fetching All questions: ", error);
            dispatch(setGetQuestionsLoader(false));
        }
    };

export const getBibleQuestion = (item, batchSize) => async (dispatch, getState) => {
  dispatch(setGetQuestionsLoader(true));
  try {
      const collectionRef = firebase.firestore().collection("b_questions");
      let query = collectionRef.orderBy("create_At", "asc");

      if (batchSize) {
          query = query.limit(batchSize);
      }

      if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
      }

      const unsubscribe = query.onSnapshot(async (snapshot) => {
          const questions = [];
          for (const doc of snapshot.docs) {
              const questionData = doc.data();
              const chatNumber = questionData.chatNumber;
              if (chatNumber) {
                  try {
                      const chatDoc = await firebase
                          .firestore()
                          .collection("Chats")
                          .doc(chatNumber)
                          .get();
                      const chatData = chatDoc.data();
                      if (chatData) {
                          questions.push({
                              ...questionData,
                              id: doc.id,
                              chatNum: chatData.chatNum,
                          });
                      }
                  } catch (error) {
                      console.error("Error fetching chat data: ", error);
                  }
              } else {
                  questions.push({
                      ...questionData,
                      id: doc.id,
                      chatNum: "",
                  });
              }
          }

          const lastDocument =
              snapshot.docs.length > 0
                  ? snapshot.docs[snapshot.docs.length - 1]
                  : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().quest.bibleQuestions;
          const newQuestions = questions.filter((question) => {
              const exists = previousQuestions.some(
                  (prevQuestion) => prevQuestion.id === question.id
              );
              return !exists;
          });

          let active = true;
          if (newQuestions.length === 0) {
              active = false;
              if (!item) {
                  alert("No More Bible Questions");
              }
          }

          const updatedQuestions = item
              ? [...previousQuestions, ...questions]
              : questions;

          dispatch({
              type: "BIBLE_QUESTIONS",
              payload: updatedQuestions,
              lastDocument: newItem,
              active,
          });
          dispatch(setGetQuestionsLoader(false));
      });

      return () => unsubscribe();
  } catch (error) {
      console.error("Error fetching Bible questions: ", error);
      dispatch(setGetQuestionsLoader(false));
  }
};
  
export const getQuestionsByTopic = (topicId, topicname) => async (dispatch) => {
    dispatch(setGetQuestionsLoader(true));
    try {
        await firebase
            .firestore()
            .collection("Questions")
            .where("topicId", "==", topicId)
            .onSnapshot(async (query) => {
                let questionData = [];
                for (let doc of query.docs) {
                    let chatNumber = doc.data().chatNumber;
                    let chatData = await firebase
                        .firestore()
                        .collection("Chats")
                        .doc(chatNumber)
                        .get()
                        .then(async (doc) => {
                            if (doc.exists) {
                                return doc.data();
                            }
                        });
                    questionData.push({
                        id: doc.id,
                        ...doc.data(),
                        chatNum: chatData.chatNum,
                        topic: topicname,
                    });
                }
                dispatch({
                    type: "FETCH_QUESTIONS_BY_TOPIC",
                    payload: questionData,
                });
                dispatch(setGetQuestionsLoader(false));
            });
    } catch (error) {
        console.error("Error fetching By Topic: ", error);
        dispatch(setGetQuestionsLoader(false));
    }
};
export const clearBibleQuestion = () => async (dispatch) => {
    dispatch({
        type: "BIBLE_QUESTIONS",
        payload: [],
        lastDocument: {},
        active: true,
    });
};
export const clearCompleteQuestion = () => async (dispatch) => {
    dispatch({
        type: "LOAD_QUESTION_MORE",
        payload: [],
        lastDocument: {},
        active: true,
    });
};
export const clearSearchAllQuestion = () => async (dispatch) => {
    dispatch({ type: "FETCH_All_QUESTION", payload: [] });
};

export const getQuestionByTopic = (topicId, topicname, page, pageSize) => async (dispatch) => {
  dispatch(setGetQuestionsLoader(true))
  try {
    const startIndex = (page - 1) * pageSize
    const snapshot = await firebase
      .firestore()
      .collection("Questions")
      .where("topicId", "==", topicId)
      .orderBy("createdAt", "desc")
      .limit(pageSize)
      .offset(startIndex)
      .get();

    const questionData = []
    for (let doc of snapshot.docs) {
      const chatNumber = doc.data().chatNumber
      const chatData = await firebase
        .firestore()
        .collection("Chats")
        .doc(chatNumber)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            return doc.data()
          }
        })
      questionData.push({
        id: doc.id,
        ...doc.data(),
        chatNum: chatData.chatNum,
        topic: topicname,
      })
    }

    dispatch({
      type: "FETCH_QUESTIONS_BY_TOPIC",
      payload: questionData,
    })
    dispatch(setGetQuestionsLoader(false))
  } catch (error) {
    console.error(error)
    dispatch(setGetQuestionsLoader(false))
  }
}
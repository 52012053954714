import React from 'react'

const BiographyText = () => {
  return (
    <div className='w-3/4 sm:w-1/2 flex items-start justify-start'>
      <p className='text-xs sm:text-2xl w-full sm:w-3/4 font-comforta-700 whitespace-pre-line'>
      Островский Павел Константинович (иерей Павел Островский) - родился 7 июля 1982 года в Москве. 
      Учился в Коломенской духовной семинарии.
      С 2014 по 2018 — настоятель Никольского храма в Павшинской пойме.
      С 2018 и по сей день — настоятель Георгиевского храма в Нахабино.
      <br/>
      Ведет миссионерскую деятельность в социальных сетях, в том числе, через канал на YouTube. 
      Сейчас запустил такие программы как «Помолчим» и «Помолчим в кавычках»,  «Есть разговор», «Простыми словами о православии».
      <br/>
      Отец Павел завоевал доверие и авторитет среди современной молодежи. При Георгиевском храме проходят молодежные встречи «ПяТерки».
      <br/>
      Является руководителем созданной им благотворительной организации «Добрые дела».
      </p>
    </div>
  )
}

export default BiographyText

import React from 'react'
import FormSendEmail from './FormSendEmail'
import MailToLink from './MailToLink'


const SendEmailSection = () => {
  return (
    <section className='w-full h-full flex flex-col items-center justify-center my-20 ' id='contacts-section'>
      <div className='m-10 flex flex-col items-center space-y-2 sm:space-y-6'>
        <h1 className='text-lg font-comforta-700 sm:text-5xl whitespace-nowrap'>Отправить сообщение</h1>
        <MailToLink/>
      </div>
      <FormSendEmail/>
    </section>
  )
}

export default SendEmailSection
